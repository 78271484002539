<template>
  <div class="home-page">
    <div class="title">一键换肤管理界面</div>
    <div class="button-create">
      <el-button type="primary" @click="handleCreate">创 建</el-button>
    </div>
    <div class="list-container">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        border
        :row-class-name="selectRowClassName"
      >
        <el-table-column prop="title" label="配置" min-width="180"> </el-table-column>
        <el-table-column label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="openContentDialog(scope.row)">添加</el-button>
            <el-button
              type="text"
              @click="handleSelect(scope.row.id)"
              :disabled="scope.row.selected === select.selected"
              >{{ scope.row.selected === select.selected ? '已选中' : '选中' }}</el-button
            >
            <el-button type="text" @click="openContentDialog(scope.row, true)">查看详情</el-button>
            <el-button type="text" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AddContentDialog
      :visible.sync="addContentDialogShow"
      :id="id"
      :isDetail="isDetail"
      @confirm="getCenterControlList"
    />
    <CreateDialog :visible.sync="createDialogShow" @confirm="getCenterControlList" />
  </div>
</template>

<script>
import AddContentDialog from './components/addContentDialog.vue'
import CreateDialog from './components/createDialog.vue'
import { getCenterControlList, deleteCenterControl, selectCenterControl } from '@/apis/index'
export default {
  name: 'HomePage',
  components: {
    AddContentDialog,
    CreateDialog
  },
  created() {
    this.getCenterControlList()
  },
  data() {
    return {
      tableData: [],
      addContentDialogShow: false,
      createDialogShow: false,
      id: '',
      isDetail: false,
      select: {
        selected: 1,
        unSelected: 0
      }
    }
  },
  methods: {
    selectRowClassName({ row }) {
      if (row.selected === this.select.selected) {
        return 'select-row'
      }
      return ''
    },
    getCenterControlList() {
      getCenterControlList().then((res) => {
        this.tableData = res.data.list
      })
    },
    handleCreate() {
      this.createDialogShow = true
    },
    openContentDialog(row, isDetail = false) {
      this.addContentDialogShow = true
      this.id = row.id
      this.isDetail = isDetail
    },
    handleSelect(id) {
      selectCenterControl({
        id
      }).then(() => {
        this.$message.success('选中成功')
        this.getCenterControlList()
      })
    },
    handleDelete(id) {
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteCenterControl({
            id
          }).then(() => {
            this.$message.success('删除成功')
            this.getCenterControlList()
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  padding: 16px;
  .title {
    font-size: 28px;
    line-height: 1.5;
  }
  .button-create {
    margin: 16px 0;
  }
  .el-table .select-row {
    background-color: #f0f9eb;
  }
}
</style>
