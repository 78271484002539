<template>
  <div class="show-page">
    <img :src="sourceUrl" v-if="type === 0" class="img" />
    <video v-else controls autoplay loop muted="false" class="video">
      <source :src="sourceUrl" />
    </video>
  </div>
</template>
<script>
import { getCenterSource } from '@/apis/index'
export default {
  name: 'ShowPage',
  data() {
    return {
      centerId: 0,
      screenId: 1,
      type: 0,
      sourceUrl: ''
    }
  },
  created() {
    this.screenId = this.$route.query.screenId
    this.getCenterSource(true)
  },
  methods: {
    getCenterSource(loop = false) {
      getCenterSource({
        centerId: this.centerId,
        screenId: Number(this.screenId)
      }).then((res) => {
        this.centerId = res.centerId ? res.centerId : this.centerId
        if (res.data) {
          this.type = res.data.list.find((item) => item.screen_id == this.screenId).type
          this.sourceUrl = res.data.list.find((item) => item.screen_id == this.screenId).source_url
        }
        if (loop) {
          setInterval(() => {
            this.getCenterSource(false)
          }, 10000)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.show-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.img {
  width: 540px;
  height: 1920px;
  object-fit: cover;
}
.video {
  width: 100%;
  height: 100%;
}
</style>
