import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import '@/plugins/element'
import '@/styles/common.css'

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: (h) => h(App)
})
