import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/views/HomePage'
import ShowPage from '@/views/ShowPage'
import UploadPage from '@/views/UploadPage'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: HomePage,
    name: 'HomePage',
    meta: {
      title: '一键换肤管理界面'
    }
  },
  {
    path: '/show',
    component: ShowPage,
    name: 'ShowPage'
  },
  {
    path: '/upload',
    component: UploadPage,
    name: 'UploadPage'
  }
]

export default new Router({
  mode: 'history',
  routes
})
