<template>
  <el-dialog
    :title="isDetail ? '中心控制内容详情' : '中心控制内容创建'"
    :visible.sync="dialogVisible"
    width="800px"
    top="5vh"
    @open="handleOpen"
    @close="handleClose"
  >
    <div class="dialog-body">
      <el-table :data="sourceList" style="width: 100%" stripe border>
        <el-table-column label="序号" prop="screenId" width="60" align="center"></el-table-column>
        <el-table-column label="上传类型" min-width="100" align="center">
          <template slot-scope="scope">
            <el-radio-group
              v-model="scope.row.type"
              @input="() => handleRemoveUrl(scope.row)"
              :disabled="isDetail"
            >
              <el-radio v-for="(item, index) in radioList" :key="index" :label="item.value">{{
                item.label
              }}</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="上传图片/视频" min-width="200" align="center">
          <template slot-scope="scope">
            <el-upload
              v-if="!isDetail"
              class="uploader"
              action="https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/fbx_file_upload"
              drag
              :show-file-list="false"
              :on-success="(res) => handleSuccess(res, scope.row)"
              :before-upload="(file) => beforeUpload(file, scope.row.type)"
            >
              <div
                class="btn-close"
                v-if="scope.row.sourceUrl"
                @click.stop="handleRemoveUrl(scope.row)"
              >
                <i class="el-icon-error"></i>
              </div>
              <img
                v-if="scope.row.type === TYPE.IMAGE && scope.row.sourceUrl"
                :src="scope.row.sourceUrl"
                class="uploader-file"
              />
              <video
                v-else-if="scope.row.type === TYPE.VIDEO && scope.row.sourceUrl"
                class="uploader-file"
                controls
              >
                <source :src="scope.row.sourceUrl" />
              </video>
              <template v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </template>
            </el-upload>
            <template v-else>
              <img
                v-if="scope.row.type === TYPE.IMAGE && scope.row.sourceUrl"
                :src="scope.row.sourceUrl"
                class="detail-file"
              />
              <video
                v-else-if="scope.row.type === TYPE.VIDEO && scope.row.sourceUrl"
                class="detail-file"
                controls
              >
                <source :src="scope.row.sourceUrl" />
              </video>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleConfirmAddContent" v-if="!isDetail">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { createCenterControlContent, centerControlContentDetail } from '@/apis/index'
const TYPE = {
  IMAGE: 0,
  VIDEO: 1
}
export default {
  name: 'AddContentDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      TYPE,
      sourceList: [
        {
          screenId: 1,
          type: TYPE.IMAGE,
          sourceUrl: ''
        },
        {
          screenId: 2,
          type: TYPE.IMAGE,
          sourceUrl: ''
        },
        {
          screenId: 3,
          type: TYPE.IMAGE,
          sourceUrl: ''
        },
        {
          screenId: 4,
          type: TYPE.IMAGE,
          sourceUrl: ''
        },
        {
          screenId: 5,
          type: TYPE.IMAGE,
          sourceUrl: ''
        }
      ],
      radioList: [
        {
          label: '图片',
          value: TYPE.IMAGE
        },
        {
          label: '视频',
          value: TYPE.VIDEO
        }
      ],
      isShowConfirm: true
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleSuccess(res, row) {
      const prefix = 'https://ky3d.com/addons/yb_guanwang/core'
      row.sourceUrl = `${prefix}/${res.info4}/${res.info2}`
    },
    beforeUpload(file, type) {
      if (type === TYPE.IMAGE) {
        const isImg = file.type.slice(0, 5) === 'image'
        if (!isImg) {
          this.$message.error('请上传图片格式的文件')
          return false
        }
      } else if (type === TYPE.VIDEO) {
        const isVideo = file.type.slice(0, 5) === 'video'
        if (!isVideo) {
          this.$message.error('请上传视频格式的文件')
          return false
        }
      }
    },
    handleRemoveUrl(row) {
      row.sourceUrl = ''
    },
    handleConfirmAddContent() {
      createCenterControlContent({
        id: this.id,
        sourceList: this.sourceList
      }).then((res) => {
        this.$message.success('创建成功')
        this.dialogVisible = false
        this.$emit('confirm')
      })
    },
    handleClose() {
      this.sourceList.forEach((item) => {
        item.type = TYPE.IMAGE
        item.sourceUrl = ''
      })
    },
    handleOpen() {
      centerControlContentDetail({
        id: this.id
      }).then((res) => {
        if (res.data.list) {
          this.sourceList = res.data.list
          this.isShowConfirm = false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.uploader {
  position: relative;
  ::v-deep .el-upload-dragger {
    width: 320px;
  }
  .uploader-file {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
  .btn-close {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 10px;
    top: 10px;
    z-index: 999;
    .el-icon-error {
      font-size: 16px;
      color: #f56c6c;
    }
  }
}
.detail-file {
  display: block;
  margin: 0 auto;
  max-height: 360px;
  max-width: 360px;
}
</style>
