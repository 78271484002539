<template>
  <div class="upload-page">
    <p class="tip">请上传图片，图片规格参考750*1624</p>
    <div class="image-container">
      <img :src="imgUrl"/>
      <div class="btn-close" v-if="imgUrl && imgUrl != defaultUrl" @click.stop="handleRemoveUrl">
        <i class="el-icon-error"></i>
      </div>
    </div>
    <div class="button-group">
      <el-upload
        class="btn"
        action="w7t/app/index.php?c=entry&a=wxapptwo&do=fileUploadFbx&m=super_cards"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :on-success="handleSuccess"
      >
        <el-button type="primary">选择图片</el-button>
      </el-upload>
      <el-button class="btn" @click="handleSave">保存</el-button>
    </div>
  </div>
</template>
<script>
import { savePtolist } from '@/apis/index'
export default {
  name: 'UploadPage',
  data() {
    return {
      imgUrl: 'https://qn.ky3d.com/super_card/20230905-153721.jpg',
      defaultUrl: 'https://qn.ky3d.com/super_card/20230905-153721.jpg',
      imgPath:"",
      originimg:"",
      precedence: this.$route.query.precedence
    }
  },
  methods: {
    beforeUpload(file) {
      const isImg = file.type.slice(0, 5) === 'image'
      if (!isImg) {
        this.$message.error('请上传图片格式的文件')
        return false
      }
    },
    handleSuccess(res) {
      const prefix = 'https://ky3d.com/addons/yb_guanwang/core'
      this.imgUrl = res.data.data.imgUrl
      this.imgPath = res.data.data.imgPath
      this.originimg = res.data.data.originimg
    },
    handleRemoveUrl() {
      this.imgUrl = this.defaultUrl
    },
    handleSave() {
       
        this.savePtolist();
    },
    savePtolist() {
      savePtolist({
        imgurl: this.imgUrl,
        imgpath: this.imgPath,
        precedence:this.precedence,
        uid:964
      }).then((res) => {
        if(res.errno == 0){
            this.$message({
                message: '保存成功，请耐心等待数字人制作完成',
                type: 'success'
            });
            setTimeout(()=>{
                window.location.reload();
            },3000)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-page {
  margin: 0px auto;
  width: 600px;
  padding: 16px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .tip {
    font-size: 16px;
    line-height: 1.5;
  }
  .image-container {
    position: relative;
    margin-top: 16px;
    width: 100%;
    height: 600px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    text-align: center;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    .btn-close {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 1;
      cursor: pointer;
    }
  }
  .button-group {
    margin-top: 32px;
    .btn {
      margin-top: 20px;
      width: 100%;
    }
    ::v-deep .el-upload {
      width: 100%;
      button {
        width: 100%;
      }
    }
    ::v-deep .el-button {
      font-size: 16px;
      border-radius: 20px;
    }
  }
}
</style>
