import Vue from 'vue'
import {
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  DatePicker,
  Checkbox,
  CheckboxGroup,
  Message,
  MessageBox,
  Radio,
  RadioGroup,
  Image,
  Table,
  TableColumn,
  Dialog,
  Upload
} from 'element-ui'

Vue.use(Button)
Vue.use(Select)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Image)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dialog)
Vue.use(Upload)

Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
