import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
  baseURL: '/', // url = base url + request url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.error !== 0 && res.errno !== 0) {
      Message.error(res.errorMsg || 'Error')
      return Promise.reject(new Error(res.errorMsg || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    // Message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
