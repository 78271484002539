import request from '@/utils/request'

export const getCenterControlList = (params) => {
  return request({
    url: 'apis/api/index/centerControlList',
    method: 'get',
    params
  })
}

export const createCenterControl = (data) => {
  return request({
    url: 'apis/api/index/createCenterControl',
    method: 'post',
    data
  })
}

export const createCenterControlContent = (data) => {
  return request({
    url: 'apis/api/index/createCenterControlContent',
    method: 'post',
    data
  })
}

export const deleteCenterControl = (data) => {
  return request({
    url: 'apis/api/index/deleteCenterControl',
    method: 'post',
    data
  })
}

export const centerControlContentDetail = (data) => {
  return request({
    url: 'apis/api/index/centerControlContentDetail',
    method: 'post',
    data
  })
}

export const selectCenterControl = (data) => {
  return request({
    url: 'apis/api/index/selectCenterControl',
    method: 'post',
    data
  })
}

export const getCenterSource = (data) => {
  return request({
    url: 'apis/api/index/getCenterSource',
    method: 'post',
    data
  })
}

export const savePtolist = (params) => {
    return request({
      url: 'w7t/app/index.php?c=entry&a=wxapptwo&do=savePtolist&m=super_cards',
      method: 'get',
      params
    })
  }