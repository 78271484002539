<template>
  <el-dialog
    title="中心控制创建"
    :visible.sync="dialogVisible"
    width="600px"
    top="15vh"
    @close="handleClose"
  >
    <div class="dialog-body">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="标题" label-width="80px" prop="title">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { createCenterControl } from '@/apis/index'
export default {
  name: 'CreateDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        title: ''
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        createCenterControl(this.form).then(() => {
          this.dialogVisible = false
          this.$emit('confirm')
        })
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped></style>
